<template>
  <div class="aditional-purchase-info-container">
    <div class="flex aditional-purchase-info-operate">
      <div class="operate-l">加购信息</div>
      <el-button class="refuse operate-r" type="primary" @click="handleOperate('addProduct')" v-if="orderDetailInfo.status === 3 || orderDetailInfo.status === 4">加购产品</el-button>
    </div>
    <div class="order-list-container">
      <ul class="order-list" v-for="(item, i) in addProductInfos" :key="i">
        <li class="list-item">
          <div class="flex item-header" :style="getCurrentBgC(item.orderStatus)">
            <div class="item-header-l">
              <span>创建时间：{{ item.createTime }}</span>
              <span>加购单号：{{ item.orderNo }}</span>
              <span v-if="item.orderStatus == 1 && item.countdownMinute">
                剩余：{{ item.countdownMinute > 9 ? item.countdownMinute : '0' + item.countdownMinute }}:{{ item.countdownSecond > 9 ? item.countdownSecond : '0' + item.countdownSecond }}自动取消
              </span>
            </div>
            <div class="item-header-r">{{ item.orderStatusName }}</div>
          </div>
          <div class="flex item-content">
            <div class="item-common item-content-img">
              <ul class="flex item-content-img-item" v-for="(itemS, s) in item.productListDtoList" :key="s + 's'">
                <el-image class="item-content-img-l" :src="itemS.coverPictureUrl" alt="">
                  <span class="el-image-error" slot="error">暂无图片</span>
                </el-image>
                <li class="item-content-img-r">
                  <div class="flex img-r-info">
                    <span class="img-r-info-title">{{ itemS.productName }}</span>
                    <span class="img-r-info-text">￥{{ itemS.salePrice }}</span>
                  </div>
                  <div class="flex img-r-info">
                    <span class="img-r-info-text"></span>
                    <span class="img-r-info-text">x{{ itemS.number }}</span>
                  </div>
                  <div class="flex img-r-info">
                    <span class="img-r-info-text">规格：{{ itemS.specification }}</span>
                  </div>
                  <div class="flex img-r-info">
                    <span class="img-r-info-text">计费单位：{{ itemS.priceUnit }}</span>
                    <span class="img-r-info-text" style="color: #e86247" v-if="itemS.refundStatus == 1">申请退款</span>
                    <span class="img-r-info-text" style="color: #e86247" v-if="itemS.refundStatus == 3">已退款:￥{{ itemS.refundAmount }}</span>
                  </div>
                </li>
              </ul>
            </div>
            <div class="item-common item-money">
              <div class="item-lable" style="font-size: 18px">实付：￥{{ item.payAmount }}</div>
              <div class="item-text">合计：￥{{ item.payAmount }}</div>
            </div>
            <div class="item-common item-content-butoon">
              <el-button class="button-detail" type="primary" @click="handleOperate('serachDetail', item)">查看详情</el-button>
              <el-button type="primary" @click="handleOperate('searchPayLink', item)" v-if="item.orderStatus == 1">查看付款码</el-button>
              <el-button class="refuse" type="primary" @click="handleOperate('cancelOrder', item)" v-if="item.orderStatus == 1">取消订单</el-button>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <AddProductPopups popupsTitle="新增加购产品" :currenTab="currenTab" :currentData="currentData" :showState="isAddProduct" v-on:hidePopups="isAddProduct = false" />
    <CancelOrderPopups popupsTitle="取消订单" :currentData="currentData" :showState="isCancelOrder" v-on:hidePopups="isCancelOrder = false" />
    <SearchPayOrderPopups popupsTitle="请客户扫描下方付款码完成付款" :currentData="currentData" :showState="isSearchPayOrder" v-on:hidePopups="isSearchPayOrder = false" />
  </div>
</template>

<script>
import AddProductPopups from './addProduct';
import CancelOrderPopups from './cancelOrder';
import SearchPayOrderPopups from './searchPayOrder';
export default {
  props: {
    orderDetailInfo: {
      type: Object,
      default: () => ({}),
    },
    addProductInfos: {
      type: Array,
      default: () => [],
    },
    currenTab: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    AddProductPopups,
    CancelOrderPopups,
    SearchPayOrderPopups,
  },
  computed: {
    getCurrentBgC() {
      return (status) => {
        let sty = {};
        switch (status) {
          case 1:
            sty = { backgroundColor: '#fcefec', color: '#E86247' };
            break;
          case 2:
          case 3:
            sty = { backgroundColor: '#FCF3DE', color: '#E3904A' };
            break;
          case 4:
          case 5:
            sty = { backgroundColor: '#E9EFFC', color: '#2861E2' };
            break;
          case 6:
            sty = { backgroundColor: '#EDEDED', color: '#000000' };
            break;
          case 7:
          case 8:
            sty = { backgroundColor: '#E5F5F4', color: '#00A199' };
            break;
        }
        return sty;
      };
    },
  },
  data() {
    return {
      isAddProduct: false,
      isCancelOrder: false,
      isSearchPayOrder: false,
      currentData: {},
    };
  },
  methods: {
    handleOperate(mark, item) {
      switch (mark) {
        case 'addProduct':
          this.currentData = {
            serviceId: this.$parent.detailsInfo.serviceId,
            orderProduct: {
              productId: this.$parent.detailsInfo.orderProduct.productId,
            },
            inType: 'detailComponents',
          };
          // console.log(this.currentData);
          this.isAddProduct = true;
          break;
        case 'serachDetail':
          this.$router.push({ path: '/aditionalPurchaseInfoDetail', query: { serviceId: this.$route.query.serviceId, orderId: item.orderId } });
          break;
        case 'searchPayLink':
          this.currentData = item;
          this.isSearchPayOrder = true;
          break;
        case 'cancelOrder':
          this.currentData = item;
          this.isCancelOrder = true;
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.aditional-purchase-info-container {
  padding: 24px 20px;
  .aditional-purchase-info-operate {
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;
    .operate-l {
      font-weight: 500;
      font-size: 14px;
    }
    .operate-r {
    }
    .refuse {
      background-color: #fff;
      color: #2861e2;
      border: 1px solid #2861e2;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .order-list-container {
    background-color: #fff;
    font-size: 14px;
    .order-list {
      margin-bottom: 20px;
      border: 1px solid #e0e0e0;

      .list-item {
        .item-header {
          padding: 13px 17px;
          justify-content: space-between;
          color: #363f47;
          .item-header-l {
            span:nth-of-type(2) {
              margin: 30px;
            }
          }
          .item-header-r {
          }
        }
        .item-content {
          .item-common {
            flex: 1;
            padding: 16px;
            border-right: 1px solid #e0e0e0;
          }
          .item-common:last-child {
            border: none;
          }
          .item-lable {
            margin-bottom: 12px;
            font-weight: 500;
          }
          .item-text {
            margin-bottom: 12px;
          }
          .item-img-text {
            margin-bottom: 12px;
            color: #666666;
          }
          .item-content-img {
            padding: 0;
            .item-content-img-item {
              padding: 20px;
              border-bottom: 1px solid #e0e0e0;
              .item-content-img-l {
                width: 80px;
                height: 80px;
                margin-right: 20px;
                vertical-align: middle;
              }
              .item-content-img-r {
                width: 100%;
                .img-r-info {
                  justify-content: space-between;
                  .img-r-info-title {
                    font-weight: 500;
                  }
                  .img-r-info-text {
                    margin-bottom: 4px;
                  }
                }
              }
              &:last-child {
                border: none;
              }
            }
          }
          .item-money {
            max-width: 300px;
          }
          .item-content-butoon {
            max-width: 160px;
            ::v-deep .el-button {
              display: block;
              margin: 0 0 12px 0;
              width: 100%;
            }
            ::v-deep .el-button:last-child {
              margin: 0;
            }
            .button-detail {
              background-color: #e9effc;
              color: #2861e2;
              border: 1px solid #e9effc;
              &:hover {
                opacity: 0.8;
              }
            }
            .refuse {
              background-color: #fff;
              color: #2861e2;
              border: 1px solid #2861e2;
              &:hover {
                opacity: 0.8;
              }
            }
          }
        }
      }
    }
    .pagination {
      padding: 20px 0 24px 0;
      text-align: right;
    }
  }
}
</style>
