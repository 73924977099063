<template>
  <div class="server-record">
    <TimeLine class="server-record-time-line" :data="activities" />
  </div>
</template>

<script>
import TimeLine from '@/components/TimeLine';
export default {
  props: {
    serverReords: {
      type: Array,
      default: () => []
    }
  },
  components: {
    TimeLine
  },
  data() {
    return {
      activities: []
    };
  },
  watch: {
    serverReords: {
      immediate: true,
      deep: true,
      handler(value) {
        this.activities = value.map(item => {
          item['time'] = item.createTime;
          return item;
        });
      }
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.server-record {
  padding: 24px;
  .server-record-time-line {
    width: 40%;
  }
}
</style>
``
