<template>
  <div class="common-sty server-evaluation" v-if="orderEvaluationDetail.environmentScore">
    <!-- <el-descriptions class="info-cotainer" :column="1">
      <el-descriptions-item label="评价时间">2021-08-20 12:00</el-descriptions-item>
    </el-descriptions> -->
    <div class="evaluation-title">
      <span class="title-mark"></span>
      <span class="title-text">整体环境评分</span>
      <span class="title-text-show"><el-rate disabled allow-half :colors="['#E86247', '#E86247', '#E86247', '#E86247', '#E86247']" v-model="orderEvaluationDetail.environmentScore"></el-rate></span>
    </div>
    <el-descriptions class="score-cotainer" :column="4" :labelStyle="{ width: '104px' }">
      <el-descriptions-item :label="itemP.dictionaryName" v-for="(itemP, p) in surroundings.childs" :key="p">
        <el-rate disabled allow-half :colors="['#E86247', '#E86247', '#E86247', '#E86247', '#E86247']" v-model="itemP.score"></el-rate>
      </el-descriptions-item>
    </el-descriptions>
    <div class="evaluation-title">
      <span class="title-mark"></span>
      <span class="title-text">整体服务评分</span>
      <span class="title-text-show"><el-rate disabled allow-half :colors="['#E86247', '#E86247', '#E86247', '#E86247', '#E86247']" v-model="orderEvaluationDetail.serviceScore"></el-rate></span>
    </div>
    <el-descriptions class="score-cotainer" :column="4" :labelStyle="{ width: '104px' }">
      <el-descriptions-item :label="itemS.dictionaryName" v-for="(itemS, s) in serversRate.childs" :key="s">
        <el-rate disabled allow-half :colors="['#E86247', '#E86247', '#E86247', '#E86247', '#E86247']" v-model="itemS.score"></el-rate>
      </el-descriptions-item>
    </el-descriptions>
    <div class="evaluation-title">
      <span class="title-mark"></span>
      <span class="title-text">评价</span>
    </div>
    <div class="score">
      <div class="score-content">
        <div class="text">{{ orderEvaluationDetail.evaluation }}</div>
        <span class="score-img" v-for="(itemj, index) in orderEvaluationDetail.evaluationPictureUrl" :key="index">
          <el-image class="certificate-img" :src="itemj" :preview-src-list="[orderEvaluationDetail.evaluationPictureUrl]" alt=""><span class="el-image-error" slot="error">暂无图片</span></el-image>
        </span>
      </div>
      <div class="reply-text" v-if="!!orderEvaluationDetail.evaluationReply">
        <span class="caption">供应商回复：</span>
        {{ orderEvaluationDetail.evaluationReply }}
      </div>
    </div>
    <div class="submit-btn-group" v-if="!!orderEvaluationDetail.evaluation">
      <el-button class="submit-btn" type="primary" @click="handleReply()" v-if="!orderEvaluationDetail.evaluationReply">去回复</el-button>
      <el-button class="submit-btn-modify" @click="handleModifyReply()" v-if="!!orderEvaluationDetail.evaluationReply">去修改回复</el-button>
    </div>
    <div class="evaluation-title">
      <span class="title-mark"></span>
      <span class="title-text">投诉建议</span>
    </div>
    <div class="score">
      <div class="score-content">
        <div class="text">{{ orderEvaluationDetail.suggest }}</div>
        <span class="score-img" v-for="(item, key, i) in orderEvaluationDetail.suggestPictureUrl" :key="i">
          <el-image class="certificate-img" :src="item" :preview-src-list="[orderEvaluationDetail.suggestPictureUrl]" alt=""><span class="el-image-error" slot="error">暂无图片</span></el-image>
        </span>
      </div>
      <div class="reply-text" v-if="!!orderEvaluationDetail.suggestReply">
        <span class="caption">平台回复：</span>
        {{ orderEvaluationDetail.suggestReply }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    orderEvaluationDetail: {
      type: Object,
      default: () => ({})
    },
    surroundings: {
      type: Object,
      default: () => ({})
    },
    serversRate: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {};
  },
  methods: {
    handleReply(type) {
      this.$router.push({ path: '/replySupplyEvaluationList', query: { id: this.orderEvaluationDetail.serviceId } });
    },
    handleModifyReply(type) {
      this.$router.push({ path: '/replySupplyEvaluationList', query: { id: this.orderEvaluationDetail.serviceId } });
    }
  }
};
</script>

<style lang="scss" scoped>
.server-evaluation {
  padding: 20px;
  .evaluation-title {
    display: -webkit-flex; /* 新版本语法: Chrome 21+ */
    display: -webkit-box; /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
    display: -moz-box; /* 老版本语法: Firefox (buggy) */
    display: -ms-flexbox; /* 混合版本语法: IE 10 */
    display: flex;
    align-items: center;
    color: #000000;
    padding: 11px 0;
    border-bottom: 1px solid #ededed;
    margin: 0 20px;
    .title-mark {
      width: 3px;
      height: 20px;
      margin: 0 20px 0 -20px;
      background-color: #363f47;
      border-radius: 0px 3px 3px 0px;
    }
    .title-text {
      margin-right: 20px;
    }
  }
  .score-cotainer {
    padding: 32px 24px 39px 24px;
  }
  .score {
    font-size: 14px;
    background: #f7f7f7;
    margin: 20px;
    .score-content {
      border-bottom: 1px solid #ededed;
      padding: 20px;
      .text {
        padding: 20px 0 20px 0;
      }
    }
    .score-img {
      margin-right: 16px;
    }
    .reply-text {
      padding: 20px;
      .caption {
        color: #9a9d9e;
      }
    }
    .certificate-img {
      width: 100px;
      height: 100px;
    }
  }
  .reply-button-group {
    margin: 20px;
  }
  .submit-btn-group {
    margin: 0 20px 40px 20px;
    .submit-btn {
    }
    .submit-btn-modify {
      margin-left: 0;
    }
  }
}
</style>
