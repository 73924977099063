<template>
  <div class="photos">
    <div class="photos-promt">如需删除照片，请电话联系：{{ phone }}</div>
    <div class="photos-list">
      <el-image class="photos-img" v-for="item in photos" :key="item.url" :src="item.url" :preview-src-list="previews" lazy><span class="el-image-error" slot="error">暂无图片</span></el-image>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    photos: {
      type: Array,
      default: () => []
    },
    phone: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      previews: []
    };
  },
  watch: {
    photos: {
      immediate: true,
      deep: true,
      handler(value) {
        this.previews = value.map(item => {
          return item.url;
        });
      }
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.photos {
  padding: 22px 40px;
  .photos-promt {
    padding: 16px 0;
    font-weight: 400;
    color: #9a9d9e;
    font-size: 14px;
  }
  .photos-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 164px);
    grid-row-gap: 12px;
    grid-column-gap: 12px;
    .photos-img {
      width: 164px;
      height: 164px;
    }
  }
}
</style>
