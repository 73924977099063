<template>
  <div class="order-detail-container">
    <div class="order-header" v-loading="boxLoading">
      <div class="header-title">
        <span>服务订单号：{{ detailsInfo.serviceNo }}</span>
        <span class="request-refund" style="background-color:#fcefec; color: #E86247" v-if="detailsInfo.status === 2">{{ detailsInfo.statusName }}</span>
        <span class="request-refund" style="background-color:#FCF3DE; color: #E3904A" v-if="detailsInfo.status === 3">{{ detailsInfo.statusName }}</span>
        <span class="request-refund" style="background-color:#E9EFFC; color: #2861E2" v-if="detailsInfo.status === 4">{{ detailsInfo.statusName }}</span>
        <span class="request-refund" style="background-color:#EDEDED; color: #000000" v-if="detailsInfo.status === 5">{{ detailsInfo.statusName }}</span>
        <span class="request-refund" style="background-color:#E5F5F4; color: #00A199" v-if="detailsInfo.status === 8">{{ detailsInfo.statusName }}</span>
      </div>
      <el-descriptions :column="5">
        <el-descriptions-item label="派单时间">{{ detailsInfo.applyAppointmentTime }}</el-descriptions-item>
        <el-descriptions-item label="预约提交时间">{{ detailsInfo.applyAppointmentTime }}</el-descriptions-item>
        <el-descriptions-item label="接单时间" v-if="!!detailsInfo.confirmOrderTime">{{ detailsInfo.confirmOrderTime }}</el-descriptions-item>
        <el-descriptions-item label="到店时间" v-if="!!detailsInfo.confirmStoreTime">{{ detailsInfo.confirmStoreTime }}</el-descriptions-item>
        <el-descriptions-item label="服务完成时间" v-if="!!detailsInfo.finishTime">{{ detailsInfo.finishTime }}</el-descriptions-item>
      </el-descriptions>
      <div class="header-button-group">
        <el-button type="primary" @click="handleOperate('enterOrder')" v-if="detailsInfo.status === 2">确认接单</el-button>
        <el-button type="primary" @click="handleOperate('enterStop')" v-if="detailsInfo.status === 3">确认到店</el-button>
        <el-button type="primary" @click="handleOperate('enterServer')" v-if="detailsInfo.status === 4">确认完成服务</el-button>
        <el-button type="primary" @click="handleOperate('uploadImg')" v-if="detailsInfo.status === 5 || detailsInfo.status === 8">上传照片</el-button>
        <el-button class="refuse" type="primary" @click="handleOperate('refuseOrder')" v-if="detailsInfo.status === 2">拒绝接单</el-button>
        <el-button class="refuse" type="primary" @click="handleOperate('addProduct')" v-if="detailsInfo.status === 3 || detailsInfo.status === 4">加购产品</el-button>
      </div>
    </div>
    <div class="header-content" v-loading="boxLoading">
      <ul class="flex tab-list">
        <li class="tab-list-item" :class="item.checked ? 'item-checked' : ''" v-for="(item, i) in tabList" :key="i" @click="handleTab(item)">
          {{ item.name }}
          <span v-if="item.num">({{ item.num }})</span>
        </li>
      </ul>
      <div class="header-tab-content">
        <detailServerOrderInfo :orderDetailInfo="detailsInfo" v-if="currenTab.name === '服务单信息'" />
        <detailAditionalPurchaseInfo :orderDetailInfo="detailsInfo" :currenTab="currenTab" :addProductInfos="addProductInfos" v-loading="addProductLoading" v-else-if="currenTab.name === '加购信息'" />
        <detailServerRecord :serverReords="serverReords" v-loading="serverReordsLoading" v-else-if="currenTab.name === '服务记录'" />
        <detailServerProtocol :serverProtocol="serverProtocol" v-else-if="currenTab.name === '服务协议'" />
        <detailPhotos :photos="photos" :phone="connectPhone" v-loading="photosLoading" v-else-if="currenTab.name === '照片库'" />
        <detailServerEvaluation
          :orderEvaluationDetail="orderEvaluationDetail"
          :surroundings="surroundings"
          :serversRate="serversRate"
          v-loading="evaluationLoading"
          v-else-if="currenTab.name === '服务评价'"
        />
      </div>
    </div>
    <RefuseOrderPopups popupsTitle="拒绝接单原因" :showState="isRefuseOrder" :currentData="currentData" v-on:hidePopups="isRefuseOrder = false" />
    <SuccessServerPopups popupsTitle="确认完成服务" :showState="isSuccessServer" :currentData="currentData" v-on:hidePopups="isSuccessServer = false" />
    <AddProductPopups popupsTitle="新增加购产品" :showState="isAddProduct" :currenTab="currenTab" :currentData="currentData" v-on:hidePopups="isAddProduct = false" />
    <UploadPhotoPopups popupsTitle="上传照片" :showState="isUploadPhoto" :currentData="currentData" v-on:hidePopups="isUploadPhoto = false" />
  </div>
</template>

<script>
import RefuseOrderPopups from './components/refuseOrder';
import SuccessServerPopups from './components/successServer';
import AddProductPopups from './components/addProduct';
import UploadPhotoPopups from './components/uploadPhoto';

import detailServerOrderInfo from './components/detailServerOrderInfo';
import detailAditionalPurchaseInfo from './components/detailAditionalPurchaseInfo';
import detailServerRecord from './components/detailServerRecord';
import detailServerProtocol from './components/detailServerProtocol';
import detailPhotos from './components/detailPhotos';
import detailServerEvaluation from './components/detailServerEvaluation';

import { getDictionaryList } from '@/api/common';
import { getOrderDetail, getDetailAddOrderList, getDetailPhotos, getDetailServerRecord, getDetailServerProtocol, getDetailPhone, enterOrder, enterStore, getDetailEvaluation } from '@/api/order';

export default {
  components: {
    RefuseOrderPopups,
    SuccessServerPopups,
    AddProductPopups,
    UploadPhotoPopups,

    detailServerOrderInfo,
    detailAditionalPurchaseInfo,
    detailServerRecord,
    detailServerProtocol,
    detailPhotos,
    detailServerEvaluation
  },
  data() {
    return {
      serviceId: undefined,
      orderServiceId: undefined,
      serviceId: undefined,
      boxLoading: false,
      serverReordsLoading: false,
      photosLoading: false,
      evaluationLoading: false,
      addProductLoading: false,
      detailsInfo: {},
      addProductInfos: {}, //加购信息
      serverReords: [], //服务记录
      serverProtocol: {}, //服务协议
      photos: [], //照片库
      connectPhone: '', //照片库-联系电话
      orderEvaluationDetail: {}, //订单评价信息
      surroundings: {},
      serversRate: {},
      tabList: [
        { name: '服务单信息', num: 0, checked: true },
        { name: '加购信息', num: 0, checked: false },
        { name: '服务记录', num: 0, checked: false },
        { name: '服务协议', num: 0, checked: false },
        { name: '照片库', num: 0, checked: false },
        { name: '服务评价', num: 0, checked: false }
      ],
      currenTab: { name: '服务单信息', num: 0, checked: true },
      isRefuseOrder: false,
      isSuccessServer: false,
      isAddProduct: false,
      isUploadPhoto: false,
      currentData: {}
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        this.boxLoading = true;

        this.orderServiceId = this.$route.query.orderServiceId;
        this.serviceId = this.$route.query.serviceId;

        this.detailsInfo = (await getOrderDetail({ data: this.serviceId })).data || {};

        this.addProductInfos = (await getDetailAddOrderList({ data: { serviceId: this.detailsInfo.serviceId, isAddition: 1 } })).data.content || [];

        if (this.addProductInfos.length) {
          this.tabList[1].num = this.addProductInfos.length;
        }

        this.boxLoading = false;
      } catch (error) {
        this.boxLoading = false;
      }
    },

    async getDetailAddOrder() {
      try {
        this.addProductLoading = true;

        let addProductRes = (await getDetailAddOrderList({ data: { serviceId: this.detailsInfo.serviceId, isAddition: 1 } })).data.content || [];

        this.addProductInfos = await addProductRes.map(item => {
          if (item.orderStatus == 1) {
            item['countdownMinute'] = (item.overdueTime - (item.overdueTime % 60)) / 60;
            item['countdownSecond'] = item.overdueTime % 60;
            if (item.countdownMinute > 0) {
              let countdownSetInterval = setInterval(() => {
                item.countdownSecond--;
                if (item.countdownSecond == 0) {
                  item.countdownSecond = 60;
                  item.countdownMinute--;
                }
                if (item.countdownMinute == 0) {
                  clearInterval(countdownSetInterval);
                  delete item.countdownMinute;
                  delete item.countdownSecond;
                }
              }, 1000);
            }
          }
          return item;
        });

        // console.log(JSON.parse(JSON.stringify(this.addProductInfos)));

        if (this.addProductInfos.length) {
          this.tabList[1].num = this.addProductInfos.length;
        }
        this.addProductLoading = false;
      } catch (error) {
        console.log(error);
        this.addProductLoading = false;
      }
    },
    async getDetailPhotos() {
      try {
        this.photosLoading = true;

        this.photos = (await getDetailPhotos({ data: { serviceId: this.detailsInfo.serviceId } })).data.content || [];

        this.connectPhone = (await getDetailPhone({ data: this.serviceId })).data.dictionaryValue || '';

        this.photosLoading = false;
      } catch (error) {
        this.photosLoading = false;
      }
    },

    async handleTab(item) {
      let currenObj = this.tabList.find(item => item.checked);
      currenObj.checked = false;
      item.checked = !item.checked;
      this.currenTab = item;
      switch (item.name) {
        case '服务单信息':
          this.detailsInfo = (await getOrderDetail({ data: this.serviceId })).data || {};
          break;
        case '加购信息':
          this.getDetailAddOrder();

          break;
        case '服务记录':
          try {
            this.serverReordsLoading = true;

            this.serverReords = (await getDetailServerRecord({ data: this.detailsInfo.serviceId })).data || [];

            this.serverReordsLoading = false;
          } catch (error) {
            this.serverReordsLoading = false;
          }
          break;
        case '服务协议':
          this.serverProtocol = (await getDetailServerProtocol({ data: this.detailsInfo.orderId })).data || {};
          break;
        case '照片库':
          this.getDetailPhotos();
          break;
        case '服务评价':
          try {
            this.evaluationLoading = true;
            this.orderEvaluationDetail = (await getDetailEvaluation({ data: this.detailsInfo.serviceId })).data || {};

            this.orderEvaluationDetail.evaluationPictureUrl = JSON.parse(this.orderEvaluationDetail.evaluationPictureUrl);
            this.orderEvaluationDetail.suggestPictureUrl = JSON.parse(this.orderEvaluationDetail.suggestPictureUrl);

            let surroundings = (await getDictionaryList({ data: { key: 'environment_score_type' } })).data || {};
            let serversRate = (await getDictionaryList({ data: { key: 'service_socre_type' } })).data || {};

            if (!!this.orderEvaluationDetail.evaluationScore) {
              this.orderEvaluationDetail.evaluationScore.forEach(item => {
                surroundings.childs.forEach(itemJ => {
                  if (item.scoreItemId == itemJ.dictionaryValue) {
                    itemJ.score = item.score;
                  }
                });
                serversRate.childs.forEach(itemS => {
                  if (item.scoreItemId == itemS.dictionaryValue) {
                    itemS.score = item.score;
                  }
                });
              });
            }
            this.surroundings = surroundings;
            this.serversRate = serversRate;
            this.evaluationLoading = false;
          } catch (error) {
            this.evaluationLoading = false;
          }
          break;
      }
    },
    async handleOperate(type) {
      let item = { serviceId: this.detailsInfo.serviceId };
      const h = this.$createElement;
      switch (type) {
        case 'enterOrder':
          this.$msgbox({
            message: h('div', { style: 'text-align:center' }, [
              h('i', { class: 'el-icon-success', style: 'color:green;font-size:60px; margin:10px 10px 0 0' }),
              h('p', { style: 'font-weight:bold; color：#fff' }, '接单成功！'),
              h('p', {}, '请到已接单订单进行查看')
            ]),
            showCancelButton: false,
            showConfirmButton: false,
            showClose: false
          })
            .then(() => {})
            .catch(() => {});
          await enterOrder({ data: { serviceId: item.serviceId } });
          this.getData();
          break;
        case 'refuseOrder':
          item['inType'] = 'detail';
          this.currentData = item;
          this.isRefuseOrder = true;
          break;
        case 'enterStop':
          this.$msgbox({
            message: h('div', { style: 'padding:11px' }, [
              h('i', { class: 'el-icon-warning', style: 'color:#ECA000;font-size:24px; vertical-align: middle;' }),
              h('span', { style: 'font-weight: 500;color: #000000; margin:0 0 0 6px; vertical-align: middle;' }, '确认到店吗'),
              h('p', { style: 'font-size:14px; color:#9A9D9E;margin:12px 0 0 30px' }, '是否确定到店？')
            ]),
            showCancelButton: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            showClose: false
          })
            .then(async () => {
              await enterStore({ data: { serviceId: item.serviceId } });
              this.$notify.success({ title: '成功', message: '到店成功', duration: 2000 });
              this.getData();
            })
            .catch(() => {});

          break;
        case 'enterServer':
          item['inType'] = 'detail';
          this.currentData = item;
          this.isSuccessServer = true;
          break;
        case 'addProduct':
          item['orderProduct'] = { productId: this.detailsInfo.orderProduct.productId };
          item['inType'] = 'detail';
          this.currentData = item;
          this.isAddProduct = true;
          break;
        case 'uploadImg':
          item['inType'] = 'detail';
          this.currentData = item;
          this.isUploadPhoto = true;
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.order-detail-container {
  .refuse {
    background-color: #fff;
    color: #2861e2;
    border: 1px solid #2861e2;
    &:hover {
      opacity: 0.8;
    }
  }
  .request-refund {
    position: relative;
    margin-left: 12px;
    // padding: 0 6px 0 12px;
    padding: 0 12px;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    // &::before {
    //   content: '';
    //   position: absolute;
    //   top: 8px;
    //   left: 4px;
    //   width: 4px;
    //   height: 4px;
    //   background-color: #fff;
    //   border-radius: 50%;
    // }
  }
  .order-header {
    padding: 20px;
    background-color: #fff;
    .header-title {
      margin-bottom: 16px;
    }
    .header-button-group {
      margin-top: 30px;
    }
  }
  .header-content {
    margin-top: 16px;
    background-color: #fff;
    .tab-list {
      font-size: 14px;
      color: #9a9d9e;
      border-bottom: 1px solid #ededed;
      .tab-list-item {
        margin-left: 30px;
        padding: 16px 0;
        &:hover {
          cursor: pointer;
          color: #000000;
          border-bottom: 3px solid #2861e2;
        }
      }
      .item-checked {
        color: #000000;
        border-bottom: 3px solid #2861e2;
      }
    }
  }
}
</style>
