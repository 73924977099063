<template>
  <div class="server-order-info">
    <div class="server-information-title">服务信息</div>
    <div class="server-information">
      <div class="flex server-information-block" v-if="!!orderDetailInfo.orderProduct">
        <el-image class="server-information-images" :src="orderDetailInfo.orderProduct.coverPictureUrl" alt=""><span class="el-image-error" slot="error">暂无图片</span></el-image>
        <div>
          <div class="server-information-name">{{ orderDetailInfo.orderProduct.productName }}</div>
          <div class="server-information-id">ID：{{ orderDetailInfo.orderProduct.productId }}</div>
        </div>
      </div>
      <el-descriptions :column="1" v-if="orderDetailInfo.orderProduct && orderDetailInfo.orderProduct.subList && orderDetailInfo.orderProduct.subList.length">
        <el-descriptions-item :label="item.productName" v-for="(item, i) in orderDetailInfo.orderProduct.subList" :key="i">{{ item.number + item.priceUnit }}</el-descriptions-item>
      </el-descriptions>
    </div>
    <el-descriptions title="预约信息" :column="2" border :labelStyle="{ width: '120px' }">
      <el-descriptions-item label="预约人">{{ orderDetailInfo.contactPersonName }}</el-descriptions-item>
      <el-descriptions-item label="联系方式">{{ orderDetailInfo.contactPhoneNumber }}</el-descriptions-item>
      <el-descriptions-item label="预约时间">{{ orderDetailInfo.appointmentShootDatetime }}</el-descriptions-item>
      <el-descriptions-item></el-descriptions-item>
    </el-descriptions>
    <el-descriptions title="支付信息" :column="1" border :labelStyle="{ width: '120px' }">
      <el-descriptions-item label="下单金额">￥{{ orderDetailInfo.payAmount || '0.00' }}</el-descriptions-item>
      <el-descriptions-item label="加购金额">￥{{ orderDetailInfo.addAmount || '0.00' }}</el-descriptions-item>
      <el-descriptions-item label="退款金额">￥{{ orderDetailInfo.refundAmount || '0.00' }}</el-descriptions-item>
      <el-descriptions-item label="服务总金额">￥{{ calculateAllMoney || '0.00' }}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
  props: {
    orderDetailInfo: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    calculateAllMoney() {
      return (Number(this.orderDetailInfo.payAmount) + Number(this.orderDetailInfo.addAmount) - Number(this.orderDetailInfo.refundAmount)).toFixed(2);
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.server-order-info {
  padding: 22px 40% 20px 20px;
  .server-information-title {
    margin: 0 0 12px 0;
    font-size: 14px;
    font-weight: 500;
  }
  .server-information {
    padding: 20px;
    background-color: #f5f5f5;
    .server-information-block {
      align-items: center;
      margin-bottom: 16px;
      font-size: 14px;
      .server-information-images {
        width: 80px;
        height: 80px;
        margin-right: 20px;
      }
      .server-information-name {
        margin-bottom: 10px;
        font-weight: 500;
        color: #000000;
      }
      .server-information-id {
        font-weight: 400;
        color: #363f47;
      }
    }
  }
}
</style>
